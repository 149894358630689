import React, { useEffect, useState } from 'react'
import useWindowWidth from '../../hooks/useWindowWidth'
import useWebpSupport from '../../hooks/useWebPSupport'
import { Image } from 'react-bootstrap'
import './responsive-image.scss';

const ResponsiveImage = ({ componentData }) => {
  const [image, setImage] = useState('')
  const windowWidth = useWindowWidth()
  const isWebpSupport = useWebpSupport();
  
  const webp = isWebpSupport ? '?fm=webp&q=80' : '';

  useEffect(() => {
    if (componentData.fields) {
      const { fields } = componentData;
      setImage(windowWidth < 768 ? fields.mobileImage.fields : fields.desktopImage.fields)
    }
  }, [windowWidth, componentData])

  if (!componentData.fields) {
    return <></>
  }

  return (
    <div className="responsive-image">
      <div className="container">
        <div className="responsive-image__wrapper">
          { image?.file?.url &&
            <Image
              src={image.file.url + webp}
              alt={image.title}
              width={image.file.details.image.width}
              height={image.file.details.image.height}
            />  
          }
        </div>
      </div>
    </div>
  )
}

export default ResponsiveImage
