import React, { useEffect, useRef } from 'react'
import { Navbar, Button, Image } from 'react-bootstrap'
//import { slide as Menu } from 'react-burger-menu'
import Menu from 'react-burger-menu/lib/menus/slide';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Types from '../../constants/ActionTypes'
import { MAP_NAVIGATION_NAME } from '../../constants/Config'
import MenuLink from './menuLink'
import LinkComponentItem from '../linkComponent/linkComponentItem'
import useWebpSupport from '../../hooks/useWebPSupport'
import './header.scss'

const handleToggleMenu = (state, toggleMenu) => {
  toggleMenu({ type: Types.MENU__TOGGLE, payload: state.isOpen })
  const menuWrap = document.querySelector('.bm-menu-wrap')
  menuWrap.style.right = menuWrap.style.right === '0px' ? '20px' : '0px'
}

const ConnectedHeader = ({ menu, closeMenu, toggleMenu }) => {
  const logoRef = useRef(null);
  const isWebpSupport = useWebpSupport();
  
  const webp = isWebpSupport ? '?fm=webp&q=80' : '';

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab' && logoRef.current === document.activeElement) {
        closeMenu();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeMenu]);

  if (!menu.isReady) {
    return <></>
  }

  const navigation = menu.data.items.find(item => item.fields.title === MAP_NAVIGATION_NAME)
  const isOpen = menu.isOpen

  return (
    <div className='header__component' style={{'--bg-url': `url(/assets/images/${webp ? 'bgMenu.webp' : 'bgMenu.png'})`}}>
      <Navbar bg='light' fixed='top'>
        <Menu right isOpen={isOpen} onStateChange={state => handleToggleMenu(state, toggleMenu)}>
          <Image src={`/assets/icons/${webp ? 'logo-nav.webp' : 'logo-nav.png'}`} width={56} height={28} loading='lazy' decoding='async' alt='bp Plus logo in white' />
          <MenuLink />
          <LinkComponentItem data={navigation.fields.linkComponentItems[2]} onclickHandle={closeMenu} children={
            fields => <Button variant='outline-success' className={'btn-log-in'}>{fields.linkComponentItemLinkTitle}</Button>
          } />
        </Menu>
        <LinkComponentItem renderChildOnly={true} data={navigation.fields.linkComponentItems[0]} children={fields => (
          <Link to='/' ref={logoRef}>
            <Image src={`${fields.linkComponentItemImage.fields.file.url}${webp}`} fetchpriority="high" width={84} height={42} className='ml-4' alt={fields.linkComponentItemImage.fields.title} />
          </Link>
        )} />
        <h6 className='business-the-clever ml-4'>Do business on the plus side.</h6>
        <div className='navbar-apply-now-button ml-auto'>
          <LinkComponentItem data={navigation.fields.linkComponentItems[1]} children={
            fields => <Button type='submit' className='apply-now mr-5'>{fields.linkComponentItemLinkTitle}</Button>
          } />
        </div>
        <h6 className='menu'>Menu</h6>
      </Navbar>
    </div>
  )
}

const mapStateToProps = store => store

const mapDispatchToProps = dispatch => ({
  closeMenu: () => dispatch({ type: Types.MENU__CLOSE }),
  toggleMenu: action => dispatch(action)
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedHeader)
